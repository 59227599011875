setTimeout(function() {
    // var dateelement = document.querySelector("#datepicker_input");
    // dateelement.addEventListener("click", function() {
    //     var element = document.querySelector("#datepicker > span > span.e-input-group-icon.e-date-icon.e-icons");
    //     element.click();
    // });

    $(document).on('click', '#datepicker_input', function() {
        $('#datepicker_input').prop('readonly', true);
        $("#datepicker > span > span.e-input-group-icon.e-date-icon.e-icons").click();
        console.log('Clicked');
    });

    $(document).on('click', '#datepicker2_input', function() {
        $('#datepicker2_input').prop('readonly', true);
        $("#datepicker2 > span > span.e-input-group-icon.e-date-icon.e-icons").click();
        console.log('Clicked');
    });

    $(document).on('click', '#timepicker_input', function() {
        $('#timepicker_input').prop('readonly', true);
        $("#timepicker > span > span.e-input-group-icon.e-time-icon.e-icons").click();
        console.log('Clicked');
    });

    $(document).on('click', '#timepicker2_input', function() {
        $('#timepicker2_input').prop('readonly', true);
        $("#timepicker2 > span > span.e-input-group-icon.e-time-icon.e-icons").click();
        console.log('Clicked');
    });

    // $(document).on('click', 'body > app-root > app-event > app-layout > div > div > div > div > form > div > div:nth-child(4) > div:nth-child(2) > div', function() {
    //     $("#datepicker > span > span.e-input-group-icon.e-date-icon.e-icons").click();
    // });

    // var timeelement = document.querySelector("#timepicker_input");
    // timeelement.addEventListener("click", function() {
    //     var element = document.querySelector("#timepicker > span > span.e-input-group-icon.e-time-icon.e-icons");
    //     element.click();
    // });
}, 2000);